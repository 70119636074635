(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("Draggable"), require("React"), require("ReactDOM"), require("gsap"), require("mobx"), require("mobxReact"), require("mobxUtils"), require("sbDataLayer"), require("sbInternalMsgBus"), require("sbRespBlockLib"));
	else if(typeof define === 'function' && define.amd)
		define("sb-betslip-block", ["Draggable", "React", "ReactDOM", "gsap", "mobx", "mobxReact", "mobxUtils", "sbDataLayer", "sbInternalMsgBus", "sbRespBlockLib"], factory);
	else if(typeof exports === 'object')
		exports["sb-betslip-block"] = factory(require("Draggable"), require("React"), require("ReactDOM"), require("gsap"), require("mobx"), require("mobxReact"), require("mobxUtils"), require("sbDataLayer"), require("sbInternalMsgBus"), require("sbRespBlockLib"));
	else
		root["sb-betslip-block"] = factory(root["Draggable"], root["React"], root["ReactDOM"], root["gsap"], root["mobx"], root["mobxReact"], root["mobxUtils"], root["sbDataLayer"], root["sbInternalMsgBus"], root["sbRespBlockLib"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__6570__, __WEBPACK_EXTERNAL_MODULE__3804__, __WEBPACK_EXTERNAL_MODULE__7196__, __WEBPACK_EXTERNAL_MODULE__9015__, __WEBPACK_EXTERNAL_MODULE__2965__, __WEBPACK_EXTERNAL_MODULE__98__, __WEBPACK_EXTERNAL_MODULE__7509__, __WEBPACK_EXTERNAL_MODULE__5239__, __WEBPACK_EXTERNAL_MODULE__858__, __WEBPACK_EXTERNAL_MODULE__9529__) {
return 